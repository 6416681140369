import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import * as sections from "../../components/sections"
import Fallback from "../../components/fallback"

export default function Offer(props) {
  const { offerPage } = props.data

  return (
    <Layout {...offerPage}>
      {offerPage.blocks.map((block) => {
        const { id, blocktype, ...componentProps } = block
        const Component = sections[blocktype] || Fallback
        const siblingIndex = offerPage.blocks
          .filter((b) => b.blocktype === blocktype)
          .findIndex((i) => i.id === id)
        return (
          <Component key={id} {...componentProps} siblingIndex={siblingIndex} />
        )
      })}
    </Layout>
  )
}

export const query = graphql`
  {
    offerPage(node_locale: { eq: "en-US" }) {
      id
      title
      description
      image {
        id
        url
      }
      blocks: content {
        id
        blocktype
        node_locale
        ...HomepageBannerContent
        ...OfferPageServicesContent
      }
    }
  }
`
